import classNames from "classnames";
import React from "react";
import { List, X } from "phosphor-react";

import { useMenuContext } from "../../contexts/MenuProvider";
import { useRefContext } from "../../contexts/RefProvider";
import { scrollTo } from "../../utils/clickHandlers";

import Button from "../button/Button";

import kargoBlack from "../../assets/kargo-logo-black.png";
import kargoWhite from "../../assets/kargo-logo-white.png";

import "./Header.scss";

const Header = ({ links, darkHeader }) => {
  const { isMenuOpened, setIsMenuOpened } = useMenuContext();
  const { heroBannerRef, contactRef } = useRefContext();

  const HeaderClass = classNames("Header-container", {
    "Header-dark": darkHeader,
  });

  const handleClickOnLogo = () => {
    if (isMenuOpened) return;
    return scrollTo(heroBannerRef);
  };

  return (
    <header className={HeaderClass}>
      <div className="Header-content">
        <img
          src={darkHeader ? kargoWhite : kargoBlack}
          alt="Kargo"
          height="auto"
          onClick={handleClickOnLogo()}
          className="Header-image"
        />
        <nav className="Header-links">
          {links.map((link) => (
            <span className="link" onClick={link.onClick} key={link.text}>
              {link.text}
            </span>
          ))}
        </nav>
        <div
          className="centered mobile-only"
          onClick={() => setIsMenuOpened((prev) => !prev)}
        >
          {isMenuOpened ? (
            <X size={32} className="link" weight="bold" />
          ) : (
            <List size={32} className="link" weight="bold" />
          )}
        </div>
        <Button
          className="desktop-only"
          text="Nous contacter"
          onClick={scrollTo(contactRef)}
          dark={!darkHeader}
        />
      </div>
    </header>
  );
};

export default Header;
