import React, { useContext, useRef } from "react";

const REF_CONTEXT = React.createContext(null);

const useRefContext = () => useContext(REF_CONTEXT);

const RefProvider = ({ children }) => {
  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);
  const heroBannerRef = useRef(null);
  const servicesRef = useRef(null);
  const zonesRef = useRef(null);

  const value = {
    aboutUsRef,
    contactRef,
    heroBannerRef,
    servicesRef,
    zonesRef,
  };

  return <REF_CONTEXT.Provider value={value}>{children}</REF_CONTEXT.Provider>;
};

export { RefProvider, useRefContext };
