import React, { useEffect, useLayoutEffect, useMemo } from "react";
import ReactDOM from "react-dom";

import { activateScroll, deactivateScroll } from "../../utils/disableScroll";

import logo from "../../assets/k-logo-white.png";

import "./SplashScreen.scss";

const SplashScreen = () => {
  const moutingPoint = useMemo(() => document.createElement("section"), []);

  useLayoutEffect(() => {
    document.body.appendChild(moutingPoint);
    return () => document.body.removeChild(moutingPoint);
  }, [moutingPoint]);

  useEffect(() => {
    deactivateScroll();
    return activateScroll;
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="SplashScreen-container">
          <div className="SplashScreen-content">
            <img src={logo} alt="kargo" width={200} />
          </div>
        </div>,
        moutingPoint
      )}
    </>
  );
};

export default SplashScreen;
