import { useCallback, useEffect, useState } from "react";

import { useMenuContext } from "./contexts/MenuProvider";
import { useRefContext } from "./contexts/RefProvider";
import { useVideoLoadingContext } from "./contexts/VideoLoadingProvider";
import { openLink, scrollTo } from "./utils/clickHandlers";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import Partners from "./components/partners/Partners";
import SplashScreen from "./components/splash-screen/SplashScreen";
import AboutUs from "./pages/about-us/AboutUs";
import HeroBanner from "./pages/hero-banner/HeroBanner";
import Services from "./pages/services/Services";
import Zones from "./pages/zones/Zones";

import "./App.scss";
import "./styles/common.scss";

const offsetTop = (ref) => ref?.current?.offsetTop;

function App() {
  const { isMenuOpened } = useMenuContext();
  const { servicesRef, zonesRef, aboutUsRef } = useRefContext();
  const { isVideoLoading } = useVideoLoadingContext();

  const [darkHeader, setIsDarkHeader] = useState(true);

  const links = [
    {
      text: "Nos services",
      onClick: scrollTo(servicesRef),
    },
    {
      text: "Nos zones et tarifs de livraison",
      onClick: scrollTo(zonesRef),
    },
    {
      text: "Notre histoire",
      onClick: scrollTo(aboutUsRef),
    },
    {
      text: "Espace client",
      onClick: openLink("https://kargoservice.everst.io/client/login"),
    },
  ];

  const isHeaderDark = useCallback(() => {
    if (window.pageYOffset < offsetTop(servicesRef)) {
      return setIsDarkHeader(true);
    }
    if (window.pageYOffset < offsetTop(zonesRef)) {
      return setIsDarkHeader(false);
    }
    if (window.pageYOffset + 1 < offsetTop(aboutUsRef)) {
      return setIsDarkHeader(true);
    }
    if (window.pageYOffset + 1 >= offsetTop(aboutUsRef)) {
      return setIsDarkHeader(false);
    }
  }, [aboutUsRef, servicesRef, zonesRef]);

  useEffect(() => {
    window.addEventListener("scroll", isHeaderDark);
    return () => window.removeEventListener("scroll", isHeaderDark);
  }, [isHeaderDark]);

  return (
    <main className="App-container">
      {isVideoLoading && <SplashScreen />}
      {isMenuOpened && <Menu links={links} />}
      <Header links={links} darkHeader={darkHeader} />
      <HeroBanner />
      <Services />
      <Zones />
      <AboutUs />
      <Partners />
      <Footer />
    </main>
  );
}

export default App;
