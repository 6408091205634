import React, { useState } from "react";

import { useRefContext } from "../../contexts/RefProvider";
import { scrollTo } from "../../utils/clickHandlers";

import Tabs from "../../components/tab/Tabs";
import Title from "../../components/title/Title";

import pricingParis from "../../assets/pricing-paris.png";
import pricingSuburb from "../../assets/pricing-suburb.png";
import zonesMap from "../../assets/zones-map.png";

import "./Zones.scss";

const Zones = () => {
  const { zonesRef, contactRef } = useRefContext();
  const [selectedTab, setSelectedTab] = useState("paris");

  const tabs = [
    {
      key: "paris",
      text: "paris - paris",
      onClick: () => setSelectedTab("paris"),
    },
    {
      key: "suburb",
      text: "paris - banlieue",
      onClick: () => setSelectedTab("suburb"),
    },
  ];

  return (
    <section className="Zones-container" ref={zonesRef}>
      <div className="Zones-content">
        <Title
          title="nos zones de livraison"
          subtitle="Nous vous livrons en vélo-cargo dans Paris et en banlieue limitrophe"
          color="var(--kargo-white)"
          centered
        />
        <img src={zonesMap} alt="Kargo paris" width="80%" />
        <div className="desktop-only" />
        <Title
          title="nos tarifs"
          subtitle={
            <span>
              Une tarification qui s'adapte en fonction de vos besoins. Vous
              avez un projet spécifique ? N'hésitez pas à &nbsp;
              <span className="link" onClick={scrollTo(contactRef)}>
                nous contacter <small style={{ fontSize: 14 }}>↗</small>
              </span>
            </span>
          }
          color="var(--kargo-white)"
          centered
        />
        <div className="Zones-pricing">
          <Tabs
            tabs={tabs}
            color="var(--kargo-white)"
            selectedTab={selectedTab}
          />
          <img
            src={selectedTab === "paris" ? pricingParis : pricingSuburb}
            alt="Kargo pricing"
            width="100%"
          />
        </div>
      </div>
    </section>
  );
};

export default Zones;
