import React from "react";

import { useRefContext } from "../../contexts/RefProvider";
import { scrollTo } from "../../utils/clickHandlers";

import CardExplainer from "../../components/card-explainer/CardExplainer";
import Title from "../../components/title/Title";

import "./Services.scss";

const explainers = [
  {
    title: "livraison à la demande",
    text: "Livraison 100% écologique le jour même, dans le délai de votre choix, pour tout colis jusqu'à 60kg et 1m³",
  },
  {
    title: "solution web",
    text: "Un dashboard intuitif pour gérer vos livraisons et votre facturation",
  },
  {
    title: "logistique",
    text: "Nous facilitons votre partie logistique en gérant votre stock, vos commandes et vos envois",
  },
  {
    title: "e-commerce",
    text: "Grâce à notre API, l'interfaçage, et la personnalisation de vos envois sont simplifiés",
  },
];

const Services = () => {
  const { servicesRef, contactRef } = useRefContext();
  return (
    <section className="Services-container" ref={servicesRef}>
      <div className="Services-content">
        <Title
          title="nos services"
          subtitle="Nos services sont assurés par une flotte de coursiers professionnels et expérimentés, tous équipés de leur propre vélo cargo."
          color="var(--kargo-black)"
        />
        <p>
          Une question ?&nbsp;
          <span className="link" onClick={scrollTo(contactRef)}>
            Contactez-nous <small style={{ fontSize: 14 }}>↗</small>
          </span>
        </p>
      </div>
      <div className="Services-cards">
        {explainers.map((card) => (
          <CardExplainer title={card.title} text={card.text} key={card.title} />
        ))}
      </div>
    </section>
  );
};

export default Services;
