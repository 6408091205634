import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";

import { useRefContext } from "../../contexts/RefProvider";
import { useVideoLoadingContext } from "../../contexts/VideoLoadingProvider";
import { useIsInViewport } from "../../utils/intersectionObserver";

import Title from "../../components/title/Title";
import Contact from "../contact/Contact";

import kargoFilm from "../../assets/kargo-film.mp4";

import "./AboutUs.scss";

const AboutUs = () => {
  const { aboutUsRef, contactRef } = useRefContext();
  const { isVideoLoading, setIsVideoLoading } = useVideoLoadingContext();

  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const videoRef = useRef(null);

  const isVideoReached = useIsInViewport(videoRef);

  const AboutUsVideoClass = classNames({
    "AboutUs-video-animation": !isVideoLoading && isVideoVisible,
  });

  const loadingVideo = () => {
    setTimeout(() => setIsVideoLoading(false), 1500);
  };

  useEffect(() => {
    if (isVideoReached) setIsVideoVisible(true);
  }, [isVideoReached]);

  const texts = [
    "Forts de notre expérience de 15 ans dans le milieu de la course à vélo parisienne, nous avons souhaité créer un service de livraison uniquement dédié à la livraison en vélo cargo.",
    "Convaincus de l'efficacité et de la durabilité de ce mode de transport de marchandises en ville face à une demande exponentielle de décarbonisation du dernier kilomètre, Kargo met en place des solutions adaptées à vos besoins grâce au renfort d'une flotte de coursiers professionnels et formés à tous les types de livraison.",
    "À la pointe des dernières innovations techniques et technologiques, Kargo est en mesure de répondre à toutes les demandes du marché et d'offrir une réelle alternative aux modes de livraison thermique.",
  ];

  return (
    <section className="AboutUs-container" ref={aboutUsRef}>
      <Title title="notre histoire" />
      <div className="AboutUs-content">
        {texts.map((text, i) => (
          <p key={i} className="AboutUs-text">
            {text}
          </p>
        ))}
      </div>
      <div ref={videoRef} className={AboutUsVideoClass}>
        <div className="AboutUs-video">
          <ReactPlayer
            url={kargoFilm}
            playing
            muted
            loop
            width="100%"
            height="auto"
            onReady={loadingVideo}
          />
        </div>
      </div>
      <div ref={contactRef} className="AboutUs-contact">
        <Contact />
      </div>
    </section>
  );
};

export default AboutUs;
